<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        size="mini"
        type="primary"
        @click="handleCreateRole"
      >
        {{ "添加用户" }}
      </el-button>
    </div>
    <el-table
      :data="userList"
      max-height="400px"
      style="width:100%;margin:30px 0;"
      highlight-current-row
      row-key="username"
      border
      lazy
      default-expand-all
      :load="load"
      :tree-props="{subsidiary: 'subsidiary', hasChildren: 'hasChildren'}"
    >
      <el-table-column
        align="center"
        label="账户"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.username }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="名称"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.realname }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="头像"
      >
        <template #default="{row}">
          <img
            :src="'https://ai.faceswap.bainiankeji.com/'+ row.avatar"
            width="60"
            height="60"
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="描述"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.remark }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="角色"
      >
        <template #default="{row}">
          {{ row.role }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态"
      >
        <template #default="{row}">
          {{ row.enable === 0 ?"禁用": "启用" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        width="150px"
      >
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleEditRole(scope)"
          >
            {{ "编辑" }}
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="deleteManageBtn(scope)"
          >
            {{ "删除" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="dialogVisible"
      width="22rem"
      :title="dialogType === 'edit' ? '修改账户' : '添加账户'"
    >
      <el-form
        v-if="dialogType !== 'edit'"
        :model="role"
        label-width="60px"
        label-position="left"
      >
        <el-form-item label="部门">
          <el-cascader
            v-model="role.userdept"
            size="mini"
            :options="deptList"
            clearable
            :props="tableDataProps"
          />
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input
            size="mini"
            v-model="role.realname"
            placeholder="请输入真实姓名"
          />
        </el-form-item>
        <el-form-item label="账户">
          <el-input
            size="mini"
            v-model="role.username"
            placeholder="请输入账户"
          />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            size="mini"
            v-model="role.ppassword"
            placeholder="请输入密码"
          />
        </el-form-item>
        <el-form-item
          label="等级"
          v-if="userList[0].role ==='超级管理员'"
        >
          <el-select
            v-model="role.userrole"
            class="m-2"
            placeholder="请选择等级"
            size="mini"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            size="mini"
            v-model="role.remark"
            placeholder="请输入描述"
          />
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="upload-demo"
            :multiple="false"
            :show-file-list="true"
            :file-list="defaultFileList"
            action="https://ai.faceswap.bainiankeji.com/manageusers/"
            name="faceimg"
            :headers="{token: token}"
            :data="{...role}"
            :on-success="handleSuccess"
            :on-change="imgSaveToUrl"
          >
            <el-button type="primary">
              上传图片
            </el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form
        v-if="dialogType === 'edit'"
        :model="role"
        label-width="40px"
        label-position="left"
      >
        <el-form-item label="姓名">
          <el-input
            size="mini"
            v-model="user.putname"
            placeholder="姓名"
          />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            size="mini"
            v-model="user.password"
            placeholder="密码"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="user.eid"
            active-text="启用"
            inactive-text="禁用"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
      </el-form>
      <div
        style="text-align:center;"
        v-if="dialogType === 'edit'"
      >
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleUpdate()"
        >
          {{ "确认" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, onBeforeMount, getCurrentInstance, ref } from 'vue'
import { allManageUsers, updateManage, deleteManage, allDept } from '@/apis/user'
import { ElMessage } from 'element-plus'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { UserActionTypes } from '@/store/modules/user/action-types'
interface User {
  uid: string
  putname: string
  password: string
  eid: number
}
interface Role {
    username: string|number
    ppassword: string
    realname: string
    userdept: string|number
    userrole: string|number
    remark: string
}
const defaultRole: Role = {
  username: '',
  ppassword: '',
  realname: '我的',
  userdept: 0,
  userrole: '',
  remark: '描述'
}
const defaultUser: User = {
  uid: '',
  putname: '',
  password: '',
  eid: 1
}
export default defineComponent({
  setup() {
    const imageFrontUrl = ref('')
    const router = useRouter()
    const route = useRoute()
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      token: useStore().state.user.token,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      options: [
        {
          value: '1',
          label: '超级管理员'
        },
        {
          value: '2',
          label: '管理员'
        },
        {
          value: '3',
          label: '普通用户'
        }
      ],
      defaultFileList: [],
      tableDataProps: {
        value: 'id',
        label: 'deptname',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
        expandTrigger: 'click'
      },
      dialogVisible: false,
      role: Object.assign({}, defaultRole),
      user: Object.assign({}, defaultUser),
      dialogType: 'new',
      userList: [],
      deptList: [],
      handleUpdate: async() => {
        await updateManage({ ...state.user }).then((res: any) => {
          if (res.code === 0) {
            ctx.$message({
              type: 'success',
              message: res?.msg
            })
            state.dialogVisible = false
            useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined)
            router.push(`/login?redirect=${route.fullPath}`).catch(err => {
              console.warn(err)
            })
          } else {
            ctx.$message({
              type: 'error',
              message: res?.msg
            })
          }
        })
      },
      getAllUserNo: async() => {
        await allManageUsers().then((res: any) => {
          state.userList = res?.data
        })
      },
      deleteManageBtn: async(scope: any) => {
        const id = scope.row.id
        await deleteManage({ uid: id }).then((res: any) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: res?.msg
            })
            state.getAllUserNo()
          } else {
            ElMessage({
              type: 'error',
              message: res?.msg
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
      },
      handleEditRole: (scope: any) => {
        state.dialogType = 'edit'
        state.dialogVisible = true
        state.user = {
          uid: scope.row.id,
          putname: scope.row.realname,
          password: '',
          eid: scope.row.enable
        }
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imageUpload = async(file: any) => {
      console.log(file)
    }
    const imgSaveToUrl = (file: any) => {
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
        return
      }
      imageUpload(file)
    }
    const handleSuccess = (res: any) => {
      console.log(state.role)
      if (res.code === 0) {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: '添加成功'
          })
          state.dialogVisible = false
          state.role = {
            username: '',
            ppassword: '',
            realname: '',
            userdept: 0,
            userrole: '',
            remark: ''
          }
          state.defaultFileList = []
        }, 1000)
      } else {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: res?.msg
          })
          state.dialogVisible = false
        }, 1000)
      }
    }
    const getAllDept = async() => {
      await allDept().then((res: any) => {
        if (res.code === 0) {
          state.deptList = res?.data
        } else {
          ctx.$message({
            type: 'danger',
            message: '暂无数据'
          })
        }
      })
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      getAllDept()
      state.getAllUserNo()
    })
    return {
      ...toRefs(state),
      imgSaveToUrl,
      handleSuccess
    }
  }
})
</script>
