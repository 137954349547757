
import { defineComponent, reactive, toRefs, onBeforeMount, getCurrentInstance, ref } from 'vue'
import { allManageUsers, updateManage, deleteManage, allDept } from '@/apis/user'
import { ElMessage } from 'element-plus'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { UserActionTypes } from '@/store/modules/user/action-types'
interface User {
  uid: string
  putname: string
  password: string
  eid: number
}
interface Role {
    username: string|number
    ppassword: string
    realname: string
    userdept: string|number
    userrole: string|number
    remark: string
}
const defaultRole: Role = {
  username: '',
  ppassword: '',
  realname: '我的',
  userdept: 0,
  userrole: '',
  remark: '描述'
}
const defaultUser: User = {
  uid: '',
  putname: '',
  password: '',
  eid: 1
}
export default defineComponent({
  setup() {
    const imageFrontUrl = ref('')
    const router = useRouter()
    const route = useRoute()
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      token: useStore().state.user.token,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      options: [
        {
          value: '1',
          label: '超级管理员'
        },
        {
          value: '2',
          label: '管理员'
        },
        {
          value: '3',
          label: '普通用户'
        }
      ],
      defaultFileList: [],
      tableDataProps: {
        value: 'id',
        label: 'deptname',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
        expandTrigger: 'click'
      },
      dialogVisible: false,
      role: Object.assign({}, defaultRole),
      user: Object.assign({}, defaultUser),
      dialogType: 'new',
      userList: [],
      deptList: [],
      handleUpdate: async() => {
        await updateManage({ ...state.user }).then((res: any) => {
          if (res.code === 0) {
            ctx.$message({
              type: 'success',
              message: res?.msg
            })
            state.dialogVisible = false
            useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined)
            router.push(`/login?redirect=${route.fullPath}`).catch(err => {
              console.warn(err)
            })
          } else {
            ctx.$message({
              type: 'error',
              message: res?.msg
            })
          }
        })
      },
      getAllUserNo: async() => {
        await allManageUsers().then((res: any) => {
          state.userList = res?.data
        })
      },
      deleteManageBtn: async(scope: any) => {
        const id = scope.row.id
        await deleteManage({ uid: id }).then((res: any) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: res?.msg
            })
            state.getAllUserNo()
          } else {
            ElMessage({
              type: 'error',
              message: res?.msg
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
      },
      handleEditRole: (scope: any) => {
        state.dialogType = 'edit'
        state.dialogVisible = true
        state.user = {
          uid: scope.row.id,
          putname: scope.row.realname,
          password: '',
          eid: scope.row.enable
        }
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imageUpload = async(file: any) => {
      console.log(file)
    }
    const imgSaveToUrl = (file: any) => {
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
        return
      }
      imageUpload(file)
    }
    const handleSuccess = (res: any) => {
      console.log(state.role)
      if (res.code === 0) {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: '添加成功'
          })
          state.dialogVisible = false
          state.role = {
            username: '',
            ppassword: '',
            realname: '',
            userdept: 0,
            userrole: '',
            remark: ''
          }
          state.defaultFileList = []
        }, 1000)
      } else {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: res?.msg
          })
          state.dialogVisible = false
        }, 1000)
      }
    }
    const getAllDept = async() => {
      await allDept().then((res: any) => {
        if (res.code === 0) {
          state.deptList = res?.data
        } else {
          ctx.$message({
            type: 'danger',
            message: '暂无数据'
          })
        }
      })
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      getAllDept()
      state.getAllUserNo()
    })
    return {
      ...toRefs(state),
      imgSaveToUrl,
      handleSuccess
    }
  }
})
